import React from "react";

interface FooterProp {
  breadcrumb: string;
}

const Footer: React.FC<FooterProp> = ({ breadcrumb }) => (
  <>
    <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>
    <footer className="mainFooter">
      <div className="wrapper">
        <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
          <ul>
            <li>
              <a href="/news">News</a>
              {breadcrumb ? " > " + breadcrumb : ""}
            </li>
          </ul>
        </div>
        <div className="footerNotAnotherFlexboxButAGrid linklist">
          <div className="flex-item1">
            <ul>
              <li>
                <a href="/dokumentationsloesungen/heime/">
                  <strong>Heime</strong>
                </a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/spitaeler/">
                  <strong>Spitäler</strong>
                </a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/rehabilitation/">
                  <strong>Rehabilitation</strong>
                </a>
              </li>
            </ul>
          </div>
          <div className="flex-item2">
            <ul>
              <li className="partiallyHidden">
                <a href="/dokumentationsloesungen/wicare-doc-l/">
                  <span className="is-visible-desktop">WiCare|Doc</span>
                  <span className="is-visible-tablet-until-PC">WCD</span>
                  -L BESA
                </a>
              </li>
              <li className="partiallyHidden">
                <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                  <span className="is-visible-desktop">WiCare|Doc</span>
                  <span className="is-visible-tablet-until-PC">WCD</span>
                  -L RAI-NH
                </a>
              </li>
              <li className="partiallyHidden">
                <a href="/dokumentationsloesungen/wicare-doc-b/">
                  <span className="is-visible-desktop">WiCare|Doc</span>
                  <span className="is-visible-tablet-until-PC">WCD</span>
                  -B
                </a>
              </li>
              <li className="partiallyHidden">
                <a href="/dokumentationsloesungen/wicare-doc-h/">
                  <span className="is-visible-desktop">WiCare|Doc</span>
                  <span className="is-visible-tablet-until-PC">WCD</span>
                  -H
                </a>
              </li>
              <li className="partiallyHidden">
                <a href="/dokumentationsloesungen/wicare-doc-r/">
                  <span className="is-visible-desktop">WiCare|Doc</span>
                  <span className="is-visible-tablet-until-PC">WCD</span>
                  -R
                </a>
              </li>
            </ul>
          </div>
          <div className="flex-item3">
            <ul>
              <li className="partiallyHidden">
                <a href="/dokumentationsloesungen/wicare-tacs/">
                  WiCare|now tacs<sup>&#174;</sup>
                </a>
              </li>
              <li className="partiallyHidden">
                <a href="/dokumentationsloesungen/wicare-lep/">
                  WiCare|now LEP<sup>&#174;</sup>
                </a>
              </li>
              <li className="partiallyHidden">
                <a href="/dokumentationsloesungen/wicare-le/">WiCare|now LE</a>
              </li>
            </ul>
          </div>
          <div className="flex-item4">
            <ul>
              <li>
                <a href="/portrait/">
                  <strong>Über uns</strong>
                </a>
              </li>
              <li className="partiallyHidden">
                <a href="/portrait/lehrlinge/">Lernende</a>
              </li>
              <li className="partiallyHidden">
                <a href="/portrait/partner/">Partner</a>
              </li>
              <li className="partiallyHidden">
                <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
              </li>
              <li>
                <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
              </li>
            </ul>
          </div>
          <div className="flex-item5">
            <ul>
              <li>
                <a href="/support/" className="supportLink">
                  <strong>Support</strong>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row copyright">
        <div className="wrapper">
          <div className="column">
            © 2025 WigaSoft. Alle Rechte vorbehalten.&nbsp;
          </div>
          <div className="column has-padding-left">
            <ul>
              <li>
                <a href="/datenschutz/">Datenschutzerklärung</a>
              </li>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
            </ul>
          </div>
          <div className="column align-right">
            <a href="/portrait/kontakt/" className="anfahrt">
              <img
                id="location-pointer"
                src="/assets/images/icons/icon-anfahrt.svg"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  </>
);

export default Footer;
